.form-type-list{
    text-align: justify;
    > ul {
        list-style: none;
        > li {
            padding: 0.5rem;
            font-size: 15px;
            // font-family: 'Poppins-Regular';
            >span {
                color: #015B97;
                padding-right: 0.5rem;
            }
        }
    }
}

.select-form-type{
    > .container{
        >.row{
            border-bottom: 1px solid #CBDAE2;
        }
    } 
}
.type-title{
    font-size: 15px;
            // font-family: 'Poppins-Regular';
}

// .create-campaign > :first-child{
//     position: absolute;
//     left: 10%;
//     font-size: 18px;
//     font-family: 'Poppins-Regular';
// }