.announcement-bar{
    background-color: #006400c9;
    position: sticky;
    color: white;
    width: 100%;
    text-align: center;
    z-index: 9999;
    > div > div > p > p > a {
        color: white !important;
        text-decoration: underline;
    }
}

.announcement_dialog{
    > p > a {
        color: #1E7FC6 !important;
        text-decoration: underline;
    }
}