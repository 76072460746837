    


.stripe {
    > p {
        font-size: 0.85rem;
    }

    &--connect{
        > div {
            > span {
                > span{
                    padding-left: 0.2rem;
                }
            }
        }
    }
}

.general-info{
    > p{
        font-size: 0.85rem;
        > a:hover{
            color: #015B97;
        }
    }
}

.stripe-settings{
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.icon-rotate{
    transform: rotate(90deg);
}