.device-area {
    height: 500px;
    padding: 1.2rem;
    overflow-x: hidden;
    border: 2px dotted grey;
    border-radius: 20px;
    background-color: #d3d3d34a;
}

.tile {
    margin-bottom: .5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: white;
    // box-shadow: 0 0px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    // transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    cursor: grab;
    padding: 0.5rem 1rem;
    &:active{
        cursor: grabbing;
    }
    &.in-active-tile{
        cursor: not-allowed;
        opacity: 0.6;
    }
    >.tile__content {
        > div img {
            height: 43px;
            width: 43px;
            pointer-events: none;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 12px;
            padding: 0.2rem;
            border: 1px solid lightgrey;
        }
    }
}

.device-media-accordion-body{
    max-height: 400px;
    min-height: auto;
    overflow: auto;
}

.tile.dragging {
    opacity: 0.6;
  }
  .tile.dragging :where(.tile__content, i) {
    opacity: 0;
}

.device-code:hover{
    > i{
        display: block !important;
    }
    
}

.select-tile{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem
}
.selected_tiles{
    box-shadow: 0px 1px 15px rgba(19, 126, 199, 0.2);
}

.attach-tile-sticky{
    text-align: center;
    background-color: #fff;
    position: sticky;
    padding: 0.2rem;
    bottom: -1.1rem;
}