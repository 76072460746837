.form-title{
    background: #1E7FC6;
    position: sticky;
    z-index: 10;
    padding: 0.5rem;
}

// .new-campaign{
//     font-family: "Poppins-Regular";
// }

// .card{
//     > .card-body{
//         font-family: "Poppins-Regular";   
//     }
// }

.campaign-form-footer{
    // position: absolute;
    margin-top: auto !important;
    position: sticky;
    width: calc(100% + 32px);
    text-align: right;
    padding: 8px 16px;
    margin: 0px;
    margin-left: -16px;
    // margin-bottom: -16px;
    background: #f4f8fb;
    box-shadow: 0px -1px 1px rgb(19 126 199 / 20%);
    left: 0px;
    bottom: -16px;
    box-sizing: border-box;
    z-index: 9;
    display: flex;
    justify-content: space-between;
}

.left-form-panel{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #f4f8fb;
    box-shadow: 2px 1px 4px -2px rgb(19 126 199 / 40%);
    height: 100vh;
    // &.col-md-12,
    // &.col-sm-12,
    // &.col-xs-12{
    //     height: auto;
    // }
    
    overflow-y: auto;
    overflow-x: hidden;
    // max-width: 50%;
    // min-width: 25%;
}

.form-view{
    height: 100vh;
    // &.col-md-12,
    // &.col-sm-12,
    // &.col-xs-12{
    //     height: auto;
    // }
    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 1200px) {
    .form-view{
        height: auto !important;
        overflow: hidden !important;
    }
    .left-form-panel{
        height: auto !important;
        overflow: hidden !important;
    }
  }

  .choose_interval_list label{
    &.active{
        text-decoration: none;
            color: #1E7FC6;
            border-bottom: 2px solid;
    }
    position: relative;
    padding: 8px;
    border-bottom: 1px solid #CBDAE2;
    flex: 1;
    text-align: center;
    cursor: pointer;
  }

  .campaign-icon {
    &:hover{
        background: #eae9e9;
    // padding-left: 0.2rem;
    border-radius: 5px;
    }
    &.active{
        background: #eae9e9;
        // padding-left: 0.2rem;
        border-radius: 5px;
    }
  }