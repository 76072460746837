.add-campaign {
    background-color: #015B97;
    border-radius: 50px;
    padding: 1rem;
    &.disabled{
        opacity: 0.5;
    }
}

.campaign-card {
    min-height: 148px;
    >div{
        >div{
            h5{
                font-size: 1.25rem;
            }
            span{
                font-size: 0.9rem;
            }
        }
    }
    background-color: #FFFFFF;
    padding: 1rem;
    border: 1px solid #D1D1D1;
    border-radius: 0.2rem;

    // >.campaign-title {
    //     >a {
    //         color: #0C639C;
    //     }
    // }

    .camp-actions {
        &.action-toggle{
            padding: 4.5px 8px !important;
            transform: translateY(-1px);
            box-shadow: none;
            &:active{
                color: #344767 !important;    
                background-color: #EFEFEF;
            }
        }
            background-color: #EFEFEF;
            padding: 3px 8px !important;
            border-radius: 3px;
            color: #344767;
            border: 1px solid #7C909B; 
    }

    .dropdown-toggle::after{
        content: unset !important;
    }
}