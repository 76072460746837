.org-info{
    box-shadow: 0px 1px 4px rgba(19,126,199,0.2);
    border-color: #CBDAE2;
    box-sizing: border-box;
}

.org-input-field{
    border-radius: 3px;
    background: #FFFFFF;
    box-sizing: border-box;
    margin-bottom: 5px;
    padding: 6px 12px;
    border: 1px solid #CBDAE2;
}

.upload-org-logo{
    > a:hover {
        text-decoration: underline;
    }
    > img{
        max-height: 100px;
    }
    border: 1px #CBDAE2 dashed;
    padding: 5rem;
    position: relative;
    > .upload-org-logo__update{
            position: absolute;
            top: 50%;
            left: 50%;
            background: #005b97;
            border-radius: 2px;
            padding: 1rem;
            display: none;
            color: white;
            opacity: 0.8;
            transform: translate(-50%, -50%);
            cursor: pointer;
    }

    &:hover{
        .upload-org-logo__update{
            display: block;
        }
        
    }
}

.logo-actions{
    > span:hover{
        text-decoration: underline;
        color: #E93C3C;
        cursor: pointer;
    }
}