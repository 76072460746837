// .jotform-form{
//     border-right: 1px solid lightgray;
// }

.jotform-mapping-wrapper{
    border: 2px dotted lightgray;
    height: 350px;
    position: relative;
    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        > i{
            font-size: 5rem;
            padding-bottom: 1rem;
        }
    }
}