.masjid-banner-image {
    // background: linear-gradient(to right,
    //         rgba(25, 0, 47, 1),
    //         rgba(0, 48, 83, 0.71),
    //         rgba(0, 27, 47, 1)), url('../../../../public/img/masjid/public-pages/mosque.png') no-repeat top center;
    // background: url('../../../../public/img/masjid/public-pages/mosque.jpg') no-repeat top center;
    max-height: 440px;
    min-height: 320px;
    background-size: cover !important;
    color: white;
}

.banner-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;

    >p {
        font-size: 1.3rem;
    }

    >h1 {
        color: white;
    }
}

.featured-campaign {
    // display: flex;

    >div:nth-child(1) {

        // padding: 6rem;
        // >h2,
        // h4 {
        //     color: #004475;
        // }

        >span {
            color: #3E3F3F;
        }
    }

    >div:nth-child(2) {
        >img {
            max-width: 100%;
            max-height: 250px;
        }
    }
}

.remaining-quantity {
    // background-color: #DAEFFF;
    font-weight: bold;
    // color: #004475;
    border-radius: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    white-space: nowrap;
}

.donate-now {
    // background-color: #A50606;
    color: white;
    border-radius: 3px;
}

// .campaign-main-section {
//     background-image: url('../../../../public/img/masjid/public-pages/diamond-design.png');
//     // opacity: 0.5;
//     background-repeat: repeat;
//     background-blend-mode: lighten;
//     // background-color: rgb(255 255 255 / 98%)
//     // background-color: #daeffffa
// }

.product {
    min-height: 326px;
    // background-color: #004475;
    color: white;
    border-radius: 10px;

    // padding: 2rem 1rem 2rem 1rem;
    >div:nth-child(1) {
        // padding: 2rem 2rem 1rem 2rem;
        padding: 1.3rem 1.3rem 1rem 1.3rem;
    }

    &.completed {
        min-height: 260px;
        padding-bottom: 1rem;
    }

    .donate {
        padding: 1rem 1rem 2rem 1rem;
    }
}

.masjid-bottom-banner {
    background:
        url('../../../../public/img/masjid/public-pages/bottom-main-bg.png');
    // height: 600px;
    background-blend-mode: lighten;
    background-color: rgb(255 255 255 / 95%);
    // opacity: 0.05;
    background-size: cover;

    // >div {
    //     >div {
    //         >div {
    //             h4 {
    //                 color: #004475;
    //             }
    //         }
    //     }
    // }
}

.completed-campaign {
    border-top: 1px solid rgba(217, 217, 217, 0.4);
}