.upload-image-container{
    > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    padding: 3rem;
    border: 2px solid lightgray;
    border-style: dashed;
}