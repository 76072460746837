.accordion-header{
    background: white;
	// border-bottom: 1px solid lightgray;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border: 1px solid rgba(0,0,0,0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.accordion-body{
    background: white;
    // border: 1px solid lightgrey;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border: 1px solid rgba(0,0,0,0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.accordion-button:not(.collapsed)::after{
    font-family: "Font Awesome 6 Free";
    content: "\f077";
    font-weight: 900;
    transform: rotate(360deg);
}

.accordion-button::after{
    font-family: "Font Awesome 6 Free";
    content: "\f077";
    transform: rotate(180deg);
    font-weight: 900;
}