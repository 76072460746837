.payment_list{
    text-align: center;
    > div{
        // display: inline-flex;
        > .payment-type{
            // width: 100px;
            // height: 35px;
            text-align: center;
        }
    }
}

.payment-type:nth-child(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.payment-type:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.payment-type{
    &.selected{
        color: white;
        // background: aliceblue;
        // box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    // background: #005b97;
    // color: black;
    // border: 1px solid #005b97;
    padding: 0.5rem;
    // border-radius: 5px;
}

.amount-selection{
    // border: 1px solid #005b97;
    border: 1px solid #C4C3C3;
    border-radius: 3px;
    // padding: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    font-size: 1.5rem;
}

.amount-selection{
    &.selected{
        // background: aliceblue;
        // box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
}

.donation-header{
    border-radius: 5px 5px 0 0 !important;
}

.custom-amount-input:focus{
    box-shadow: 0 0 5px #999999;
    background: #fafafa;
    border-color: #999999;
}
.custom-amount-input{
    width: 100%;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    &.standalone{
        height: 60px;
    }
    height: 60px;
    font-size: 20px;
    border: 1px solid #C4C3C3;
    background-color: #fff;
    padding: 12px 25px 14px 31px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    outline: none;
    -webkit-appearance: none;
}

.btn-step-one{
    background-color: rgb(0, 143, 2);
    width: 80%;
    height: 54px;
    margin-bottom: 20px;
    text-transform: none;
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 2px 0 0 transparent;
    color: #fff;
    line-height: 27px;
}

.custom-amount-symbol{
    width: auto;
    height: auto;
    border: none;
    font-size: 1.5rem;
    line-height: 14px;
    color: #4C4C4C;
    background-color: transparent;
    padding: 0;
    top: 22px;
    left: 18px;
    font-weight: 400;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: border-color 0.1s, color 0.1s;
    -moz-transition: border-color 0.1s, color 0.1s;
    -ms-transition: border-color 0.1s, color 0.1s;
    -o-transition: border-color 0.1s, color 0.1s;
    transition: border-color 0.1s, color 0.1s;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    &.standalone{
        top: 20px;
    }
}

.main-widget{
    box-shadow: 0px 1px 6px rgb(0 0 0 / 20%);
}

.descriptive-amount{
    border: 1px solid #E5E7EB;
    padding: 10px 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
// .form-check{
//     border-bottom: dotted thin #E7E7E7;
//     >.form-check-label {
//         margin-bottom: 0.5rem;
//         >.form-check__item{
//             >.form-check__item__amount{
//                 margin-left: 3.2rem;
//                 >div >.custom-amount-input{
//                     margin: 0;
//                     border: 0;
//                     // border-bottom: 1px solid #d0d0d0;
//                     padding: 0;
//                     box-shadow: none;
//                     background-color: white;    
//                 }
//             }
//         }
//     }
// }

.donation{
    width: 100%;
    background-color: #e5e5e5;
    height: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    > .donation__received{
        height: 20px;
        // background-color: #015B97;
        width: 1%;
    }
}

.donation-widget{
    height: 100px;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
    border-radius: 4px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
}

.form--header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40em;
}

.custom-page-header{
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    // > div > img{
    //     width: 100px;
    // }
    > div > .custom-header-title{
        line-height: 35px;
        font-size: 2.3rem;
    }
    > div > .custom-header-subtitle{
        font-size: 1.2rem;
    }
    // > div:nth-child(2) {
    //     > span:nth-child(1){
    //         font-size: 2.4rem;
    //     }
    // }
    // > div:nth-child(3) {
    //     > span:nth-child(1){
    //         font-size: 1.2rem;
    //     }
    // }
    // > div > span:nth-child(1){
    //     font-size: 1rem;
    // }
}

.pledge-icon{
    background: #015B97;
    border: 1px solid darkgray;
    padding: 1rem;
    border-radius: 50px;
    color: white !important;
}