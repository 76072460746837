.loader {
  top: 10%;
    position:relative;
    animation: spinningColor 1.5s ease-in-out infinite;
    margin: 50px auto;
    border: 5px double #f0eff5;
    border-radius: 50%;
    width: 50px;
    left: 0;
    right:0;
    height: 50px;
    z-index: 9999;
  }
  
  .loader-wrapper-main{
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: #fafafa;
  }

  @keyframes spinningColor {
    0% {
      transform: rotate(360deg);
      border-top:5px dashed #f56682;
      border-bottom:5px dashed #387eff;
    }
    25% {
      border-top:5px dashed #f591a6;
      border-bottom:5px dashed #6da7f7;
    }
    50% {
      border-top:5px dashed #fd878e;
      border-bottom:5px dashed #4ba3ff;
    }
    75% {
      border-top:5px dashed #f57f8f;
      border-bottom:5px dashed #569dff;
    }
    100% {
      border-top:5px dashed #f56682;
      border-bottom:5px dashed #387eff;
    }
  }