.bg-vector{
    background-image: url("../assets/img/vector.png");
    background-repeat: repeat;
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
    // opacity: 0.2;
}

.login-main{
    background-color: #F9F9F9;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-header{
    > h4 {
        color: #00A3A7;
    }
}