.donation-preview-link{
    background-color: #f2f0f0;
    padding: 1rem;
    border-radius: 5px;
}



.campaign-widget:before{
    content: "";
    border: 0.5px solid#CBCBCB;
    position: absolute;
    height: 25px;
    top: 100%;
    left:4%
}