.quantity{
    position: relative;
    // border: 1px solid #adb5bd;
    height: 60px;
    width: 230px;
}

.total-unit-purchased{
  background: #d7dce0;
}


._qty{
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  ._qty a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    color: black;
    background: #d7dce0;
    width: 50px;
    height: 60px;
    &.plus{
        // border-left: 1px solid #adb5bd;
        // border-bottom: 1px solid #adb5bd;
    }
    &.minus{
        // border-right: 1px solid #adb5bd;
        // border-bottom: 1px solid #adb5bd;
    }
  }
  .quantity > .qty{
    background: #fff;
    border: none;
    font-size: 1.6rem;
    height: 100%;
    &:focus-visible{
      outline: none;
    }
    color: #000;
    padding-left: 10px;
    width: 100%;
    border: 1px solid #cccccc6b;
    text-align: center;
  }